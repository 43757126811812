<template>
  <a-drawer width="45%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form" :rules="rules">
      <a-form-model-item :label="$t('优惠券类型.优惠券名称')" prop="couponName">
        <a-input v-model="form.couponName" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('优惠券类型.优惠券名称')"/>
      </a-form-model-item>
<!--        <a-form-model-item :label="$t('优惠券类型.店铺')" prop="shopId" v-if="form.shopId!=0">
          <a-select style="width: 100%" v-model="form.shopId" placeholder="请选择店铺">
            <a-select-option v-for="(item, index) in shopList" :value="item.id" :key="index">
              {{ item.shopName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>-->
      <a-form-model-item :label="$t('优惠券类型.发放面额')" prop="money">
        <a-input v-model="form.money" @change="limitPrice()" suffix="¥"
                 :placeholder="$t('通用.输入.请输入')+$t('优惠券类型.发放面额')"/>
      </a-form-model-item>
      <a-form-model-item :label="$t('优惠券类型.发放数量')" prop="count">
        <a-input v-model="form.count" @change="limitSeckillPrice()"
                 :placeholder="$t('通用.输入.请输入')+$t('优惠券类型.发放数量')"/>
      </a-form-model-item>
      <a-form-model-item :label="$t('优惠券类型.满多少元使用')" prop="atLeast">
        <a-input v-model="form.atLeast" @change="limitAtLeastPrice()" suffix="¥"
                 :placeholder="$t('通用.输入.请输入')+$t('优惠券类型.发放面额')"/>
      </a-form-model-item>
      <a-form-model-item :label="$t('优惠券类型.有效日期开始时间')" prop="startTime">
        <a-date-picker v-model="form.startTime" valueFormat='YYYY-MM-DD' show-time
                       :placeholder="$t('优惠券类型.有效日期开始时间')"/>
      </a-form-model-item>
      <a-form-model-item :label="$t('优惠券类型.有效日期结束时间')" prop="endTime">
        <a-date-picker v-model="form.endTime" valueFormat='YYYY-MM-DD' show-time
                       :placeholder="$t('优惠券类型.有效日期结束时间')"/>
      </a-form-model-item>
      <a-form-model-item :label="$t('优惠券类型.是否新人优惠券')" prop="isNewCoupon">
        <a-radio-group v-model="form.isNewCoupon">
          <a-radio v-for="(item, index) in this.customDict.TFEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item :label="$t('优惠券类型.使用范围')" prop="rangeType">
        <a-select :placeholder="$t('通用.输入.请选择')+$t('优惠券类型.优惠券类型')" v-model="form.rangeType" allowClear>
          <a-select-option v-for="(item, index) in customDict.CouponTypeEnum" :value="item.type"
                           :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item :label="$t('商品.商品分类')" prop="businessId"  v-if="form.rangeType===2">
        <a-tree-select v-model="form.businessId" style="width: 100%"
                       :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="cateOptions" :placeholder="$t('通用.文本.请选择')+$t('商品分类.商品分类')"
                       :replaceFields="{ children: 'children', title: 'categoryName', key: 'id', value: 'id' }"
                       tree-default-expand-all>
        </a-tree-select>
      </a-form-model-item>

      <a-form-model-item :label="$t('通用.文本.选择商品')" prop="businessId"  v-if="form.rangeType===3" >
        <a-button type="dashed" @click="openProdSelect" danger>
          <span style="color: dodgerblue"> {{$t('通用.文本.选择商品')}}</span>
        </a-button>
      </a-form-model-item>
      <!-- 数据展示 -->
      <a-table v-if="form.rangeType===3"
               :loading="goodsLoading"
               :scroll="{ x: '160%' }"
               rowKey="id"
               :size="tableSize"
               :columns="goodsColumns"
               :data-source="form.goodsList"
               :pagination="false"
               :bordered="tableBordered">
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <template slot="picture" slot-scope="text, record">
          <div>
            <img v-if="record.picture" :src="record.picture"  style="width:60px;height:62px;" @click="handleRecordPreview(record.picture)" alt="none"/>
          </div>
        </template>

        <span slot="categoryId" slot-scope="text, record">
            {{getCategoryNameById(record.categoryId)}}
        </span>
        <span slot="brandId" slot-scope="text, record">
             {{getBrandNameById(record.brandId)}}
          </span>
        <span slot="status" slot-scope="text, record">
           <custom-dict-tag :options="customDict.GoodsStatusEnum" :value="record.status"/>
          </span>
      </a-table>
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl"/>
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{ $t('通用.按钮.保存') }}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{ $t('通用.按钮.取消') }}
          </a-button>
        </a-space>
      </div>
      <radio-goods-select-form ref="RadioGoodsSelectForm" @goodsSelect="indexGoodsModalSelect"></radio-goods-select-form>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {getCouponType, addCouponType, updateCouponType} from '@/api/coupon/couponType'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from "@/api/tool/oss";
import {listCategory} from "@/api/goods/category";
import {tableMixin} from '@/store/table-mixin'
import RadioGoodsSelectForm from "@/views/goods/goods/modules/RadioSelectForm";
import {allBrand} from "@/api/goods/brand";
import {pageShop, listShop, delShop, updateShopRecommend} from '@/api/shop/shop'

export default {
  name: 'CreateForm',
  props: {},
  components: {
    RadioGoodsSelectForm,
    CustomDictTag,
  },
  mixins: [tableMixin],
  data() {
    return {
      shopList: [],
      cateOptions: [],
      goodsLoading: false,
      labelCol: {span: 4},
      wrapperCol: {span: 14},
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      goodsColumns: [
        {
          title: this.$t('商品.商品编号'),
          dataIndex: 'goodsCode',
          width: 100,
          fixed: 'left',
        },
        {
          title: this.$t('商品.商品名称'),
          dataIndex: 'goodsName',
          width: 100,
          fixed: 'left',
        },
        {
          title: this.$t('商品.商品主图'),
          dataIndex: 'picture',
          width: 60,
          scopedSlots: {customRender: 'picture'},
        },
        {
          title: this.$t('商品.商品分类'),
          dataIndex: 'categoryId',
          width: 60,
          scopedSlots: {customRender: 'categoryId'},
        },
        {
          title: this.$t('商品.品牌'),
          dataIndex: 'brandId',
          width: 50,
          scopedSlots: {customRender: 'brandId'},
        },
        {
          title: this.$t('商品.市场价'),
          width: 50,
          dataIndex: 'marketPrice',
        },
        {
          title: this.$t('商品.商品价'),
          width: 50,
          dataIndex: 'price',
        },
        /*{
          title: this.$t('商品.商品状态'),
          dataIndex: 'status',
          width: 60,
          scopedSlots: {customRender: 'status'},
        },
        {
          title: this.$t('商品.创建时间'),
          dataIndex: 'createTime',
          width: 150,
          scopedSlots: {customRender: 'createTime'},
        },*/
      ],

      // 表单参数
      form: {
        id: null,
        goodsList: [],
        shopId: null,

        couponName: null,

        money: null,

        count: null,

        maxFetch: null,

        atLeast: null,

        needUserLevel: null,

        rangeType: null,

        businessId: null,

        startTime: null,

        endTime: null,

        state: null,

        isShow: null,

        detailMessage: null,

        isNewCoupon: null,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        couponName: [
          {required: true, message: this.$t('优惠券类型.优惠券名称') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        money: [
          {required: true, message: this.$t('优惠券类型.发放面额') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        atLeast: [
          {required: true, message: this.$t('优惠券类型.满多少元使用') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        rangeType: [
          {
            required: true,
            message: this.$t('优惠券类型.使用范围') + this.$t('通用.文本.不能为空'),
            trigger: 'change'
          }
        ],
        startTime: [
          {required: true, message: this.$t('优惠券类型.有效日期开始时间') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        endTime: [
          {required: true, message: this.$t('优惠券类型.有效日期结束时间') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        isNewCoupon: [
          {required: true, message: this.$t('优惠券类型.是否新人优惠券') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ]
      }
    }
  },
  filters: {},
  created() {  this.getCateList(); this.getBrandList();  this.getShopList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        goodsList: [],
        id: null,
        shopId: null,
        couponName: null,
        money: null,
        count: null,
        maxFetch: null,
        atLeast: null,
        needUserLevel: null,
        rangeType: null,
        businessId: null,
        startTime: null,
        endTime: null,
        state: null,
        isShow: null,
        detailMessage: null,
        isNewCoupon: null,
        createTime: null,
        remark: null
      }

    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getCouponType({"id": id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    getCateList() {
      listCategory().then(response => {
        this.categoryList = response.data;
        const cate = { id: "0", categoryName: this.$t('通用.文本.根节点'), children: [] }
        cate.children = this.handleTree(response.data, 'id','pid')
        this.cateOptions.push(cate)
      })
    },
    getBrandList() {
      allBrand().then(response => {
        this.brandList = response.data
      })
    },
    getCategoryNameById(id) {
      if (!id) {
        return "";
      }
      let cateInfo = this.categoryList.find(e => e.id === id);
      if (cateInfo) {
        return cateInfo.categoryName
      } else {
        return "";
      }
    },
    getBrandNameById(id) {
      if (!id) {
        return "";
      }
      let brandInfo = this.brandList.find(e => e.id === id);
      if (brandInfo) {
        return brandInfo.name
      } else {
        return "";
      }
    },
    getShopList() {
      listShop().then(response => {
        this.shopList = response.data
      })
    },
    openProdSelect() {
      console.log('打开商品选择框,单选模式')
      this.$refs.RadioGoodsSelectForm.openGoods(undefined, [this.form.businessId]);
    },
    indexGoodsModalSelect(records) {
      this.form.businessId = records[0].id
      this.form.goodsList = records
      console.log('商品选择了',this.form.goodsList)
    },
    limitPrice() {
      // console.log(i);
      let val = this.form.money.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, '$1');
      if (val == null || val == undefined || val == '' || val<0) {
        val = 0
      }
      if (val > 21474836) {
        val = 21474836
      }
      this.form.money = val
    },
    limitAtLeastPrice() {
      // console.log(i);
      let val = this.form.atLeast.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, '$1');
      if (val == null || val == undefined || val == '' || val<0) {
        val = 0
      }
      if (val > 21474836) {
        val = 21474836
      }
      this.form.atLeast = val
    },
    /*x限制只能输入数字,并限定最大值*/
    limitSeckillPrice() {
      // console.log(i);
      let val = this.form.count.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, '$1');
      if (val == null || val == undefined || val == '' || val<0) {
        val = 0
      }
      if (val > 21474836) {
        val = 21474836
      }
      this.form.count = val
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateCouponType(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addCouponType(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
